.brief{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brief-text{
    flex-basis: 35%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.brief-text p{
    font-family:'Satoshi-Medium';
    font-size: 20px;
}

.brief-text p::before{
    content: '';
    width: 20%;
    height: 2px;
    background: #E5761A;
    margin-bottom: 5px;
    display: block;
}

.brief-text h2{
    font-size: 36px;
    font-family:'Satoshi-Bold';
}

.brief-img{
    flex-basis: 57%;
}

.brief-img span{
    color: #1f1f1f;
    font-size: 18px;
    line-height: 32px;
    font-family:'Satoshi-Regular';
}


@media screen and (max-width:900px){
    .brief{
        flex-direction: column;
        margin-top: 50px;
    }

    .brief-text{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    } 

    .brief-text p{
        font-family:'Satoshi-Medium';
        font-size: 18px;;
        text-align: center;
    }

    .brief-text p::before{
        content: '';
        width: 70%;
        height: 2px;
        background: #E5761A;
        margin-bottom: 5px;
        display: block;
    }

    .brief-text h2{
        font-size: 36px;
        font-family:'Satoshi-Bold';
        text-align: center;
    }
 

    .brief-img{
        flex-basis: 100%;
        margin-top: 10px;
        text-align: center;
    }

    .brief-img span{
        font-size: 16px;
        line-height: 28px;
        font-family:'Satoshi-Regular';
        text-align: center;
    }
    
    
}