.cta{
    margin-top: 100px;
    background: #E5761A;
   
}

.cta-con{
    width: 80%;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    gap: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
}

.cta-con h2{
    width: 60%;
    font-size: 36px;
    font-family:'Satoshi-Bold';
    text-align: center;
}

.cta-con p{
    font-family:'Satoshi-Regular';
}

.cta-con button{
    background: #fff;
    font-family:'Satoshi-Medium';  
    border: none;
    border-radius: 30px;
    outline: none;
    padding: 10px 25px;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.cta-con button:hover{
    background: #590212;  
    color: #fff;
}

@media screen and (max-width:900px){
    
.cta-con h2{
    width: 100%;
    font-size: 24px;
    font-family:'Satoshi-Bold';
    text-align: center;
}
}