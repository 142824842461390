.service-brand{
  width: 70%;
  margin: 0 auto;
}

.service-brand h2{
    font-size: 36px;
    font-family:'Satoshi-Bold'; 
}

.service-brand p{
    font-size: 18px;
    line-height: 30px;
    font-family:'Satoshi-Regular';
}

.sub-brand{
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.sub-brand p{
    font-size: 28px;
    font-family:'Satoshi-Bold';
    margin-bottom: 5px;
}

.sub-brand span{
    font-size: 18px;
    line-height: 30px;
    font-family:'Satoshi-Regular';
}

@media screen and (max-width:900px){
    .service-brand{
        width: 90%;
        margin: 0 auto;
        text-align: center;
     }

     .service-brand h2{
        font-size: 28px;
        font-family:'Satoshi-Bold'; 
        text-align: center;
        
    }
    
       
     .sub-brand{
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
        text-align: center;
    }

    .sub-brand p{
        font-size: 20px;
        font-family:'Satoshi-Bold';
        text-align: center;
    }
    
}