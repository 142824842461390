.contact{
  position: relative;
  background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697883891/side-view-people-correcting-grammar-mistakes_tbhfax.jpg") ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  flex-direction: column;
  color: #fff;
  margin-bottom: 80px;
}

.contact-overlay{
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 6% 2%;
  padding-top: 140px;
  background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697885514/Rectangle_1_pg6gnv.png") ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-overlay h2{
  text-align: center;
}


.contact-overlay h2{
  font-size: 64px;
  font-family:'Satoshi-Bold';
}

.contact-con{
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

.contact-con h2{
  font-size: 18px;
  line-height: 30px;
  font-family:'Satoshi-Bold';
}

.contact-form{
  flex-basis: 47%;
}

form{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

  form label{
    font-family:'Satoshi-Medium';
  }

  .pad{
    margin-top: 20px;
  }

  form input{
     background: #f2f2f2;
     font-family:'Satoshi-Medium';
     color: #5F5D5D;
     outline: none;
     border: none;
     padding: 20px;
  }

  textarea{
    background: #f2f2f2;
    font-family:'Satoshi-Medium';
    color: #5F5D5D;
    outline: none;
    border: none;
    padding: 10px 20px;
    height: 100px;
  }

  .submit-btn{
    background: #430065;
    color: #fff;
    margin-top: 30px;
    transition: all ease-in 0.3s;
    font-family:'Satoshi-Medium';
    cursor: pointer;
  }

  .submit-btn:hover{
    background: #8F4DFC;
  }


  .contact-img{
    flex-basis: 47%;
 
  }

  .toast-message {
    background: darkblue;
    color: #fff;
    font-size: 18px;
    width: 34vw;
    padding: 30px 20px;
  }

  .con-ad{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    background: #f2f2f2;
    padding: 30px;
  }

  .con-ad h2{
    font-family:'Satoshi-bold';
  }

  .con-ad h2::before{
    content: '';
    width: 10%;
    height: 2px;
    background: #8F4DFC;
    margin-bottom: 5px;
    display: block;
  }

  .con-ad p{
    width: 40%;
    font-family:'Satoshi-medium';
  }


  .ad{
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: grid;
    gap: 20px;
  }

  .ad-box{
    display: flex;
    flex-direction: column;
     background: #fff;
     padding: 20px;
     transition: all ease-in 0.3s;
  }

  .ad-box:hover{
    scale: 0.96;
  }

  .ad-box p{
    width: 100%;
    font-family:'Satoshi-Bold';
    font-size: 20px;
  }

  .ad-box span{
    font-family:'Satoshi-medium';
    font-size: 15px;
  }

  .mail{
    margin-bottom: 20px;
  }

  .mail-link{
    color: #000;
    font-family:'Satoshi-medium';
  }

  .social-media{
    display: flex;
    margin-top: 20px;
  }

  .soc{
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .ad-icon{
    width: 40px;
    height: 40px;
    object-fit: cover;
    margin-bottom: 10px;
  }

  @media (min-width: 600px) {
    .ad{ 
        grid-template-columns: repeat(2, 1fr);
     }  
  }
  
  @media (min-width: 900px) {
    .ad { 
        grid-template-columns: repeat(4, 1fr); 
    } 
  }

  @media screen and (max-width:900px){
    .toast-message {
      background: darkblue;
      color: #fff;
      font-size: 18px;
      width: 80vw;
      padding: 30px 20px;
    }
    .contact-overlay h2{
      width: 100%;
      font-size: 32px;
      text-align: center;
    }

    .contact-con{
      width: 90%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 50px;
    }

    .contact-form{
      flex-basis: 100%;
    }

    form{
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      gap: 5px;
  }
  
   
    .pad{
      margin-top: 15px;
    }

    form input{
      background: #f2f2f2;
      color: #5F5D5D;
      outline: none;
      border: none;
      padding: 15px;
   }

   .con-ad{
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
    background: #f2f2f2;
    padding: 20px;
  }
   .con-ad p{
    width: 100%;
    font-family:'Satoshi-medium';
  }

  .ad{
    margin-top: 30px;
  }

  .ad-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
     background: #fff;
     padding: 20px;
     transition: all ease-in 0.3s;
  }

  .con-ad h2{
    text-align: center;
  }

  .con-ad h2::before{
    content: '';
    width: 40%;
    height: 2px;
    background: #8F4DFC;
    margin: 0 auto;
    margin-bottom: 5px;
    display: block;
  }

  .con-ad p{
    text-align: center;
  }
  }