.serv{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;  
}

.serv h3{
    font-family:'Satoshi-Regular';
}

.serv h3::before{
    content: '';
    width: 5%;
    height: 2px;
    background: #E5761A;
    margin-bottom: 5px;
    display: block;
}

.serv h2{
    font-size: 36px;
    font-family:'Satoshi-Bold';
}



.homeservice-con{
    width: 100%;
    margin-top: 30px;
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: center;
}

.homeservice-con h2{
    color: #fff;
    font-family:'Satoshi-Bold';
    border-top: 2px solid #E5761A;
}


.homeservice{
    display: flex;
    justify-content: space-between;
    gap: 60px;
}

.homeservice-box{
    width: 100%;
    padding:  25px 35px;
    display: flex;
    flex-direction: column;
    background: #fbf3fe;
    border-radius: 30px;
    color: #000;
    transition: all ease-in 0.3s;
    cursor: pointer;
}

.homeservice-box:hover{
    background: #c4600e;
    color: #fff;
}

.ellipse{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #713bc7;
    margin-bottom: 20px;
}

.homeservice-text{
    text-align: left;
}

.homeservice-text p{
    font-size: 18px;
    font-family:'Satoshi-Bold';
    margin-bottom: 10px;
}

.homeservice-text span{
    font-size: 16px;
    line-height: 24px;
    font-family:'Satoshi-Regular';

}


     
@media screen and (max-width:900px){
    .homeservice-con h2{
        font-size: 20px;
        color: #fff;
        border-top: 2px solid #8F4DFC;
    }

    
    .serv h3{
        text-align: center;
        font-family:'Satoshi-Regular';
    }

    .serv h3::before{
        content: '';
        width: 8%;
        margin: 0 auto;
        height: 2px;
        background: #E5761A;
        margin-bottom: 5px;
        display: block;
    }

    .homeservice-con{
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        display: flex;
        gap: 40px;
        flex-direction: column;
        align-items: center;
    }
    
    .homeservice{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 30px;
    } 

    .homeservice-box{
        width: 100%;
        padding:  20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: #fbf3fe;
        border-radius: 30px;
        color: #000;
    }
    

    .homeservice-text{
        padding: 20px 15px;
        text-align: center;
    }

    .homeservice-text p{
        font-size: 18px;
        margin-bottom: 10px;
    }
    
    .homeservice-text span{
        font-size: 16px;
        line-height: 26px;
    
    }
    
}