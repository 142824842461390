.about{
    position: relative;
    background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697883787/medium-shot-colleagues-posing-together_khvf2x.jpg") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    flex-direction: column;
    color: #fff;
    margin-bottom: 80px;
  }
  
  .about-overlay{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 6% 2%;
    padding-top: 140px;
    background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697885514/Rectangle_1_pg6gnv.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .about-overlay  h2{
    font-size: 64px;
    font-family:'Satoshi-Bold';
    text-align: center;
  }

  .about-con{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

  .about-text{
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}


.about-text p{
    font-family:'Satoshi-Bold';
    font-size: 24px;
}


.about-text p::before{
    content: '';
    width: 20%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.about-text span{
    color: #1f1f1f;
    font-size: 18px;
    line-height: 28px;
    font-family:'Satoshi-Regular';
}

  .about-img{
    flex-basis: 47%;
}

  .about-img img{
    width: 100%;
    height: 600px;
    object-fit: cover;
  }

  .subtext{
    width: 90%;
    margin: 0 auto;
  }

  .subtext h2{
    font-family:'Satoshi-Bold';
    font-size: 24px;
  }

  .subtext p{
    font-size: 18px;
    font-family:'Satoshi-Regular';
  }

.vision{
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    background: #430065; 
    padding: 60px;
    display: flex;
    justify-content: space-between;  
}

.vision-left{
    flex-basis: 45%;
}

.vision-left p{
    color: #fff;
    font-family:'Satoshi-Bold';
    font-size: 24px;
    margin-bottom: 20px;
}
.vision-left p::before{
    content: '';
    width: 20%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.vision-left span{
    color: #fff;
    font-size: 18px;
    line-height: 34px;
    font-family:'Satoshi-Regular';
}

.vision-right{
    flex-basis: 47%;
    padding: 10px;
    background: #8F4DFC;
}

.vision-right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.mis-val{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}

.mission {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mission h2{
    font-family:'Satoshi-Bold';
}

.mission h2::before{
    content: '';
    width: 40%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.value {
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.value h2{
    font-family:'Satoshi-Bold';
}

.value h2::before{
    content: '';
    width: 15%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.misval-text{
    display: flex;
    gap: 15px;
}

.misval-text p{
    font-family:'Satoshi-Regular';
    font-size: 18px;
    line-height: 28px;
    margin-top: -5px;
    color: #1f1f1f;
}

.misval-text img{
    width: 20px;
    height: 20px;
    object-fit: cover;
}

@media screen and (max-width:900px){
.about-overlay  h2{
    font-size: 32px;;
}

.about-con{
  flex-direction: column;  
  gap: 20px;
}

.about-text{
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
} 

.about-text p{
    font-size: 28px;
    text-align: center;
}


.about-text p::before{
content: '';
width: 80%;
height: 2px;
background: #F21D44;
margin-bottom: 5px;
display: block;
}

.about-text span{
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}

.about-img{
    flex-basis: 100%;
    margin-top: 40px;
}

.about-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vision{
    width: 90%;
    margin: 0 auto;
    margin-top: 50px;
    background: #430065; 
    padding: 40px 20px;
    display: flex;
    justify-content: space-between;  
    flex-direction: column;
    gap: 50px;
}

.vision-left{
    flex-basis: 100%;
}

.vision-left p{
    color: #fff;
    font-size: 20px;
    margin-bottom: 20px;
}

.vision-left p::before{
    content: '';
    width: 30%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.vision-left span{
    color: #fff;
    font-size: 16px;
    line-height: 32px;
}

.vision-right{
    flex-basis: 100%;
    background: #8F4DFC;
}


.mis-val{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 50px;
}

.mission {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.mission h2{
    font-size: 20px;
}

.mission h2::before{
    content: '';
    width: 40%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.value {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.value h2{
    font-size: 20px;
}

.value h2::before{
    content: '';
    width: 15%;
    height: 2px;
    background: #F21D44;
    margin-bottom: 5px;
    display: block;
}

.misval-text{
    display: flex;
    gap: 10px;
}

.misval-text p{
    font-size: 16px;
    line-height: 24px;
    margin-top: -5px;
}

}