.navbar{
    position: fixed;
    width: 100%;
    display: flex;
    background: #650088;
    z-index: 2;
    height: 16vh;
    transition: all ease-in 0.3s;
}

.logo img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.nav-con{
    width: 92%;
    margin: 0 auto;
    padding: 0 20px;
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.nav-items{
    display: flex;
    text-align: center;
    gap: 40px;
    align-items: center;
}

.nav-items li{
    padding: 6vh 0;
    list-style: none;
}

.nav-list{
    padding: 8px;
    color: #fff;
    cursor: pointer;
    font-family:'Satoshi-Medium'; 
    transition: all ease-in 0.3s;
    border-radius: 10px;
}

.nav-list:hover{
  background:#fff ;
  color: #650088;
}

.try-btn{
    display: flex;
    gap: 10px;
}

.try-btn button{
    background: #E5761A;
    color: #fff;
    font-family:'Satoshi-Medium';  
    border: none;
    border-radius: 30px;
    outline: none;
    padding: 10px 15px;
    cursor: pointer;
    transition: all ease-in 0.3s;
}

.try-btn button:hover{
    background: #c7600c;
}

.menu-toggle{
    display: none;
    background: transparent;
}

.hamBox{
    position: relative;
    border-radius: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    padding: 1.4em ;
    z-index: 3;
}

.hamBoxOpen{
    background: #292929;
}

.lineTop, .lineBottom{
    margin: 0 auto;
    position: absolute;
    display: block;
    width: 24px;
    height: 2px;
    border-radius: 10px;
    background: #fff;
    left: 0;
    right: 0;
    transform: rotate(0deg);
    transition: all 0.4s;
}

.lineTop{
    top: 1em;
}

.lineBottom{
    bottom: 1em;
}

.lineTop.spin{
    top: 1.4em;
    transform: rotate(135deg);
}

.lineBottom.spin{
    top: 1.4em;
    transform: rotate(225deg);
}


.nav-overlay{
    position: fixed;
    background: #E5761A; 
    z-index: -2;
    top:0 ;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding:0 20px ;
    transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
    
}


.nav-links{
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto;
    padding-top: 120px;
   
    display: flex;
    flex-direction: column;
    justify-content: left;
    gap: 20px;
}

.nav-item{
    width: 100%;
    list-style: none;
    position: relative;
    
}

.nav-item a{
    font-family:'Satoshi-Regular'; 
    color: #fff;
    font-size: 1.2em;
    position: relative;
    top: 0;
}

.nav-item a:hover{
    color: #590212;
}


.nav-wrapper:after{
    content: "";
    position: absolute;
    top: 180px;
    left: -10%;
    width: 120%;
    height: 100%;
    margin: 0 auto;
 }

 .menu-service{
    display: flex;
    flex-direction: column;
    gap: 20px;
 }


 
@media screen and (max-width:900px){
    .logo img{
        width: 90px;
        height:90px;
        object-fit: cover;
    }
    
    .navlinks{
        display: none;
    }

   .nav-items{
    display: none;
   }

   .nav-list{
    font-size: 1.2em;
    font-family:'Satoshi-Medium';
    padding: 0;
   }
   


   .nav-list:hover{
    background:transparent;
    color: #fff; 
  }

     
    .nav-tab{
        padding: 10px;
        background: #1B224A;
    }

    .nav-con{
        width: 99%;
        margin: 0 auto;
        padding: 10px 10px;
        background: transparent;
        display: flex;      
    }

    .try-btn{
        display: flex;
        align-items: center;
        gap: 3px;
    }

    .try-btn button{
        background: #E5761A;
        color: #fff;
        font-family:'Satoshi-Medium';  
        border: none;
        font-size: 12px;
        border-radius: 30px;
        outline: none;
        padding: 10px 8px;
        cursor: pointer;
        transition: all ease-in 0.3s;
    }
    
    .menu-toggle{
        display: block;
    }  
}