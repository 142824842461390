.scholarship-con{
    width: 90%;
    margin: 0 auto;
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}

.wrap{
    width: 50%;
    margin: 0 auto;
    margin-top: 120px;
}

.sch-left{
    flex-basis: 47%;
}

.sch-left img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.sch-right{
    flex-basis: 47%;
}

.sch-right h2{
    font-family:'Satoshi-Bold';
}

.sch-right p{
    margin-top: 20px;
    line-height: 30px;
    font-family:'Satoshi-Medium';   
}

.info{
    width: 85%;
    margin: 0 auto;
    margin-top: 50px;
    text-align: center;
    font-family:'Satoshi-Medium';   
}


.info p{
    margin-top: 10px;
    font-size: 18px;
   line-height: 30px;
}

.sch-right strong{
    font-size: 24px;
    font-family:'Satoshi-Bold'; 
}



@media screen and (max-width:900px){
    .scholarship-con{
        width: 90%;
        margin: 0 auto;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .sch-right{
        flex-basis: 100%;
    }
       
    .sch-left{
        flex-basis: 100%;
    }    

    .wrap{
        width: 90%;
        margin: 0 auto;
        margin-top: 120px;
    }
}