.hero{
    position: relative;  
    width: 100%;
    background: #fbf3fe;;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 8% 5%;
    padding-top: 160px;
  }
  
  .hero-overlay{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-basis: 45%;
  }

  .hero-overlay h2{
    color:  #000;
    font-size: 64px;
    font-family:'Satoshi-Bold';
  }

  .hero-overlay p{
    margin-top: 0;
    width: 88%;
    color:  #000;
    font-size: 18px;
    line-height: 28px;
    font-family:'Satoshi-Regular';
  }

  .about-btn{
    width: 180px;
    margin-top: 30px;
  }

  .hero-overlay button{
    width: 100%;
    gap: 30px;
    background: #E5761A;
    color: #fff;
    font-family:'Satoshi-Bold';
    padding: 15px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .hero-overlay button:hover{
    background: #c7600c;
  }

  .hero-img{
    flex-basis: 45%;
  }

  .hero-img img{
   width: 100%;
   height: 100%;
   border: 2px solid #E5761A;
   border-radius: 10px;
   object-fit: cover;

  }

   
  @media screen and (max-width:900px){
    .hero{
      position: relative;  
      width: 100%;
      color: #fff;
      display: flex;
      flex-direction: column;
      gap: 30px;
      justify-content: space-between;
    }

    .hero-overlay{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 5% 5%;
        padding-bottom: 50px;
      }
    
      .hero-overlay h2{
        width: 100%;
        font-size: 36px;
        text-align: center;
      }
    
      .hero-overlay p{
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
      }   
}
