.footer{
    background: #650088;
    color: #fff;
    padding: 50px 80px;
}

.footer-box{
    display: flex;
    justify-content: space-between;
}

.footer-links{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.footer-links p{
    font-size: 18px;
    font-family:'Satoshi-Medium';
}

.sub-link{
    color: #fff;
    font-family:'Satoshi-Regular';
    font-size: 16px;
    transition:  all ease-in 0.3s;
}

.sub-link:hover{
    color: rgb(248, 144, 248);
}

.copy{
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
    padding-top: 30px;
    padding-bottom: 0;
    border-top: 2px solid #8F4DFC;
    
    font-family:'Satoshi-Medium';
}

.disclaimer{
    display: flex;
    gap: 50px;  
    font-family:'Satoshi-Medium';
}

@media screen and (max-width:900px){
    .footer{
        background: #650088;
        color: #fff;
        padding: 40px 20px;
    }
    
    .footer-box{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 40px;
    }
    
    .footer-links{
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    
    .footer-links p{
        font-size: 18px;
        font-family:'Satoshi-Medium';
    }
    
    .footer-links span{
        font-family:'Neue-Regular';
        font-size: 14px;
    }
    
    .copy{
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;
        margin-top: 60px;
        padding-top: 30px;
        padding-bottom: 0;
        border-top: 2px solid #8F4DFC;
    }
    
    .disclaimer{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 30px;
    }  

    .disclaimer p{
        font-size: 14px;
    }
}