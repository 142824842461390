*{
  margin: 0;
  padding: 0;    
  text-decoration: none;
  box-sizing: border-box;
}

@font-face {
  font-family:'Satoshi-Bold';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Bold.otf);
}

@font-face {
  font-family:'Satoshi-Medium';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Medium.otf);
}


@font-face {
  font-family:'Satoshi-Regular';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Regular.otf);
}

@font-face {
  font-family:'Satoshi-Light';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Light.otf);
}

@font-face {
  font-family:'Satoshi-Black';
  src: url(./Fonts/Satoshi_Complete/Fonts/OTF/Satoshi-Black.otf);
}



@font-face {
  font-family:'Neue-Bold';
  src: url(./Fonts/Neue-montreal/NeueMontreal-Bold.otf);
}

@font-face {
  font-family:'Neue-Medium';
  src: url(./Fonts/Neue-montreal/NeueMontreal-Medium.otf);
}

@font-face {
  font-family:'Neue-Regular';
  src: url(./Fonts/Neue-montreal/NeueMontreal-Regular.otf);
}

@font-face {
  font-family:'Clash-Bold';
  src: url(./Fonts/ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Bold.otf);
}

@font-face {
  font-family:'Clash-Medium';
  src: url(./Fonts/ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Medium.otf);
}

@font-face {
  font-family:'Clash-Regular';
  src: url(./Fonts/ClashDisplay_Complete/Fonts/OTF/ClashDisplay-Regular.otf);
}



