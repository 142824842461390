.application-con{
    width: 50%;
    margin: 0 auto;
}

.form-disclaimer{
    width: 50%;
    margin: 0 auto;
}

.form-disclaimer p{
    font-size: 18px;
    font-family:'Satoshi-Regular';  
}

.first-header{
    font-size: 36px;
    font-family:'Satoshi-Bold';
}

.second-header{
    margin-top: 30px;
    font-size: 36px;
    font-family:'Satoshi-Bold';
}

.date{
    width: 100%;
}

select{
    background: #f2f2f2;
    font-family:'Satoshi-Medium';
    color: #5F5D5D;
    outline: none;
    border: none;
    padding: 20px;
}

.option{
    background: #f2f2f2;  
    padding: 20px;
}

.scripts{
    width: 50%;
    margin: 0 auto;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.scripts p{
    font-size: 18px;
    font-family:'Satoshi-Medium';    
}

.scripts span{
    font-size: 18px;
    font-family:'Satoshi-Regular';    
}

.scripts button{
    width: 140px;
    padding: 10px;
    margin-top: 10px;
    background: #E5761A;
    border: none;
    transition: all ease-in 0.3s;
}

.scripts button:hover{
     background: #c7600c;
}

.scripts button a{
    font-family:'Satoshi-Medium';  
    color: #fff;
}

.enq{
    width: 50%;
    margin: 0 auto;
    margin-top: 40px;   
}

.enq p{
    font-size: 18px;
    font-family:'Satoshi-Regular';     
}

.enq-btn{
    display: flex;
    gap: 10px;
    margin-top: 10px;
}


.enq-btn button{
    width: 140px;
    padding: 10px;
    margin-top: 10px;
    border-radius: 40px;
    background: transparent; 
    border: 2px solid #c7600c;
    transition: all ease-in 0.3s;
}

.enq-btn button:hover{
    background: #c7600c;
    color: #fff;
}

.enq-btn button a{
    font-family:'Satoshi-Medium';  
    color: #590212;
}

.enq-btn button a:hover{
    color: #fff;
}

@media screen and (max-width:900px){
    .application-con{
        width: 90%;
        margin:  0 auto;
    }  

    .form-disclaimer{
        width: 90%;
        margin: 0 auto;
    }
    
    .scripts{
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    
    .enq{
        width: 90%;
        margin: 0 auto;
        margin-top: 40px;   
    }
        

    .first-header{
        font-size: 24px;
        font-family:'Satoshi-Bold';
        margin-bottom: 10px;
    }

    .second-header{
        font-size: 24px;
        font-family:'Satoshi-Bold';
        margin-top: 40px;
    }
    
}