.career-con{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.career-left{
    flex-basis: 47%;
}

.career-left h2{
    font-family:'Satoshi-Bold';
}

.career-left p{
    margin-top: 20px;
    line-height: 30px;
    font-family:'Satoshi-Medium';   
}


.career-right{
    flex-basis: 47%;
}

.career-right img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 2px solid #ccc;
}

@media screen and (max-width:900px){
    .career-con{
        width: 90%;
       flex-direction: column;
       gap: 30px;
    }
    
    .career-left{
        flex-basis: 100%;
    }
    
    .career-left h2{
        font-family:'Satoshi-Bold';
    }
    
    .career-left p{
        margin-top: 20px;
        font-size: 16px;
        line-height: 24px;
        font-family:'Satoshi-Medium';   
    }
    
    
    .career-right{
        flex-basis: 100%;
    }
}