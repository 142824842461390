.study{
    position: relative;
    background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697883891/side-view-people-correcting-grammar-mistakes_tbhfax.jpg") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    flex-direction: column;
    color: #fff;
    margin-bottom: 80px;
  }
  
  .study-overlay{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 6% 2%;
    padding-top: 140px;
    background-image:url("https://res.cloudinary.com/dxnukbo0u/image/upload/v1697885514/Rectangle_1_pg6gnv.png") ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .study-overlay h2{
    text-align: center;
  }

  
  .study-overlay  h2{
    font-size: 64px;
    font-family:'Satoshi-Bold';
  }

  .stud{
    width: 90%;
    margin: 0 auto;
  }

  .study-con{
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: grid;
    gap: 20px;
}

@media (min-width: 600px) {
  .study-con { 
      grid-template-columns: repeat(2, 1fr);
   }  
}

@media (min-width: 900px) {
  .study-con { 
      grid-template-columns: repeat(4, 1fr); 
  } 
}

@media screen and (max-width:900px){
   
  .study-overlay  h2{
    font-size: 32px;
    font-family:'Satoshi-Bold';
  }
}