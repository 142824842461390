.summer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.summer-img{
    flex-basis: 47%;
}

.summer-con{
    flex-basis: 47%;  
}

.summer-con h2{
    font-size: 36px;
    font-family:'Satoshi-Bold'
}

.summer-con p{
    font-size: 18px;
    line-height: 30px;
    font-family:'Satoshi-Regular';   
}

.summer-text{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 100px;
}

.summer-text h2{
    font-size: 36px;
    font-family:'Satoshi-Bold'  
}

.summer-text strong{
    font-size: 20px; 
}

@media screen and (max-width:900px){
    .summer{
        flex-direction: column;
        gap: 30px;
    }
    .summer-img{
        flex-basis: 100%;
    }
    
    .summer-con{
        flex-basis: 100%;  
    }
    
}