.acad{
    width: 70%;
    margin: 0 auto;
    padding-top: 120px;
}


.acad-con h2{
    font-size: 36px;
    font-family:'Satoshi-Bold'
}

.acad-con p{
    font-size: 18px;
    line-height: 30px;
    font-family:'Satoshi-Regular'; 
}


.writing-services{  
    margin-top: 80px; 
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
}

.ws-img{
    flex-basis: 47%;
}

.ws-img img{
    width: 100%;
    object-fit: cover;
}

.ws-text{
    flex-basis: 47%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width:900px){
    .acad{
        width: 90%;
        margin: 0 auto;
        padding-top: 120px;
    }

    .acad-con h2{
        font-size: 28px;
        font-family:'Satoshi-Bold';
        text-align: center;
    }
    
    .acad-con p{
        font-size: 18px;
        line-height: 30px;
        font-family:'Satoshi-Regular'; 
        text-align: center;
    }
    
    .writing-services{  
        margin-top: 50px; 
        display: flex;
       flex-direction: column;
    }
       
    .ws-img{
        flex-basis: 100%;
    }

    .ws-text{
        flex-basis: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
}