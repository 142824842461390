.studydes{ 
    width: 90%;
     margin: 0 auto;
    margin-top: 120px;
   
}

.des{
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.studydes-con{
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.uni-box{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.uni-img{
    width: 100%;
    height: 230px;
    object-fit: cover;
    transition: all ease-in 0.5s;
}

.uni-img:hover{
    scale: 0.9;
}

.uni-name{
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    font-family:'Satoshi-Medium';
    text-align: center;
    transition: all ease-in 0.3s;
}

.uni-name:hover{
    background: #E5761A;
}

.study-btn{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.study-btn button{
    display: flex;
    align-items: center;
    gap: 30px;
    background: #E5761A;
    color: #fff;
    font-family:'Satoshi-Bold';
    padding:12px 20px;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: all ease-in 0.3s;
  }

  .study-btn button:hover{
    background: #c7600c; 
  }



@media (min-width: 600px) {
    .studydes-con { 
        grid-template-columns: repeat(2, 1fr);
     }  
  }

  @media (min-width: 900px) {
    .studydes-con { 
        grid-template-columns: repeat(4, 1fr); 
    } 
  }

  @media screen and (max-width:900px){
    .studydes{ 
        width: 90%;
        margin: 0 auto;
         margin-top: 60px;
       
    }

    .des{
        flex-direction: column;
    }

    .subtext{
        width: 85%;
        margin: 0 auto;
        text-align: center;
    }
    
    .subtext h2{
        font-size: 20px;
    }
    
    .subtext p{
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
    }
  }