.policy{
    width: 80%;
    margin: 0 auto;
}

.policy h2{
    font-family:'Satoshi-Bold';
    font-size: 28px;
}

.policy p{
    margin-top: 10px;
  font-family:'Satoshi-Medium';
  font-size: 18px;
  line-height: 30px;
}

.policy-con{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.policy-box p{
    width: 100%;
    font-family:'Satoshi-Bold'; 
    font-size: 24px;
}

.policy-box span{
    font-size: 18px;
    font-family:'Satoshi-Medium';
    line-height: 30px;
}

@media screen and (max-width:900px){
    .policy{
        width: 90%;
        margin: 0 auto;
    } 

    .policy h2{
        font-family:'Satoshi-Bold';
        font-size: 24px;
    }
    
    .policy p{
      font-family:'Satoshi-Medium';
      font-size: 16px;
      line-height: 26px;
    }

    .policy-con{
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    
    .policy-box p{
        width: 100%;
        font-family:'Satoshi-Bold'; 
        font-size: 20px;
    }
    
    .policy-box span{
        font-size: 16px;
        font-family:'Satoshi-Medium';
        line-height: 26px;
    }
    
    
}