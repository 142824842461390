.des-info{
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    align-items: center;
    gap: 100px; 
}

.desimg{
    flex-basis: 45%;
}

.des-img{
    width: 90%;
    object-fit: cover;
}

.des-text{
    flex-basis: 45%;
}

.des-text h2{
    font-size: 36px;
    font-family:'Satoshi-Bold';
}

.des-text p{
    font-size: 18px;
    font-family:'Satoshi-Regular'; 
}

.reason{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.reason-text{
    display: flex;
    gap: 20px;
}

.reason-text img{
    width: 20px;
    height: 20px;
   object-fit: cover;
}

.reason-text p{
    font-size: 18px;
    text-align: left;
    margin-top: -2px;
    font-family:'Satoshi-Regular'; 
}

.doc-req{
    width: 80%;
    margin: 0 auto;
    padding-top: 200px;
}

.doc-req h2{
    font-size: 36px;
    font-family:'Satoshi-Bold';
    text-align: center;
}


.doc-list{
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;   
}

.doc-list span{
    font-family:'Satoshi-Regular';  
}

@media screen and (max-width:900px){
    .doc-req{
        width: 90%;
        margin: 0 auto;
        padding-top: 150px;
    }

    
    .doc-req h2{
        font-size: 24px;
        font-family:'Satoshi-Bold';
        text-align: center;
    }

     .des-info{
        width: 90%;
        margin: 0 auto;
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 30px; 
    } 

    .des-text h2{
        font-size: 24px;
        font-family:'Satoshi-Bold';
        text-align: center;
    }
    
    .des-text p{
        text-align: center;
        font-size: 18px;
        font-family:'Satoshi-Regular'; 
    }

    .reason-text p{
        text-align: left;
        margin-top: -2px;
        font-family:'Satoshi-Regular'; 
    }
    
    .desimg{
        flex-basis: 100%;
    }

    .des-img{
        width: 100%;
        height: 290px;
        object-fit: cover;
    }
    
}