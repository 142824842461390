.cart{
    width: 90%;
    margin: 0 auto;
    height: auto;
    background: #E5761A;
    position: absolute;
    margin-top: 20px; 
    left: 0;
    right: 0;
    z-index: 1;
    padding: 30px;
    color: #fff;
    display: flex;
    justify-content: space-between; 
    gap: 40px;
    transition: all ease-in 0.3s;
    border-radius: 20px;
}


.cart.clicked{
    display: none;
}


.service-head{
    color: #fff;
    padding: 10px;
    font-family:'Satoshi-Medium'; 
    transition: all ease-in 0.3s;
    border-radius: 10px;
}


.service-head:hover{
    background: #914405;
}



.service-type {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 30px;
}

.service-type a{
    font-size: 14px;
    color: #fff;
    padding: 10px;
    transition: all ease-in 0.3s;
    font-family:'Satoshi-Medium'; 
    background: #914405;
    border-radius: 10px;
    
}

.service-type a:hover{
    background: #fff;
    background: #914405;
}

@media screen and (max-width:900px){
    .cart{
        flex-direction: column;
        gap: 20px;
        background: transparent;
        position: relative;
        padding: 0;
    }
}